<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-dialog v-model="newServiceDialog" width="800">
      <v-card class="cards pa-2">
        <span v-show="false">{{ reactivity }}</span>
        <v-card-title class="text-right">
          <h5>افزودن خدمت به درخواست</h5>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newService.name"
            placeholder="نام خدمت"
            class="pa-0"
            outlined
            dense
          ></v-text-field>
          <vuetify-money
            placeholder="قیمت"
            label="قیمت (ریال)"
            :valueWhenIsEmpty="whenIsEmpty"
            :options="vMoneyOptions"
            type="number"
            outlined
            dense
            class="mx-auto my-1"
            v-model="newService.cost"
          />
          <v-btn
            class="primary-btn"
            @click="addFreeService(newService)"
            :disabled="!newService.name || !newService.cost"
            >ثبت</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <v-row>
          <v-col md="6" cols="12">
            <h3>ثبت خدمت در منزل</h3>
          </v-col>
          <v-col md="6" cols="12">
            <v-dialog v-model="newPatientDialog" persistent width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="primary-btn newPatient-btn"
                  :class="
                    patientVisitsTimes >= 0 && patientName && !visitsBusy
                      ? 'mb-5 ms-3 me-3'
                      : 'mt-4'
                  "
                  v-bind="attrs"
                  v-on="on"
                  @click="openNewPatient"
                  style="float: left"
                >
                  <v-icon left> person_add </v-icon>
                  افزودن بیمار
                </v-btn>
              </template>
              <v-card class="cards pa-3">
                <v-card-title>
                  <h3>ثبت بیمار جدید</h3>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="12" md="6" lg="6">
                      <v-text-field
                        label="نام بیمار"
                        v-model="newPatient.firstName"
                        outlined
                        dense
                        hide-details=""
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" sm="12" md="6" lg="6">
                      <v-text-field
                        label="نام‌خانوادگی بیمار"
                        v-model="newPatient.lastName"
                        outlined
                        dense
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <div>
                    <v-select
                      label="جنسیت"
                      v-model="newPatient.gender"
                      :items="genders"
                      item-text="text"
                      item-value="value"
                      outlined
                      dense
                    ></v-select>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          label="شماره موبایل"
                          v-model="newPatient.mobile"
                          type="number"
                          outlined
                          dense
                          hide-details
                        ></v-text-field
                      ></v-col>
                      <v-col>
                        <v-text-field
                          label="تلفن ثابت"
                          v-model="newPatient.tel"
                          type="number"
                          outlined
                          dense
                        ></v-text-field
                      ></v-col>
                    </v-row>

                    <v-text-field
                      label="آدرس"
                      v-model="newPatient.address"
                      outlined
                      dense
                    ></v-text-field>
                    <v-row>
                      <v-col md="6" cols="12">
                        <v-text-field
                          label="کد ملی"
                          v-model="newPatient.natCode"
                          type="number"
                          :rules="[isID]"
                          outlined
                          dense
                        ></v-text-field
                      ></v-col>
                      <v-col md="6" cols="12">
                        <v-text-field
                          label="شماره بیمه"
                          v-model="newPatient.insurNo"
                          outlined
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0">
                        <v-select
                          label="نوع بیمه"
                          v-model="newPatient.insurType"
                          :items="insurTypes"
                          item-text="text"
                          item-value="value"
                          outlined
                          dense
                        ></v-select
                      ></v-col>
                    </v-row>
                    <v-row class="time-row">
                      <v-col class="pt-0" md="6" cols="12">
                        <v-checkbox
                          v-model="newPatient.insuranceLastSheet"
                          class="lastSheet-checkBox"
                          color="primary"
                          label="تا آخرین صفحه دفترچه"
                        >
                        </v-checkbox
                      ></v-col>
                      <v-col class="pt-0" md="6" cols="12">
                        <v-text-field
                          outlined
                          dense
                          type="text"
                          v-model="newPatient.insuranceExpirationDate"
                          label="تاریخ انقضا دفترچه"
                          :editable="true"
                          class="date-input mt-2"
                          :disabled="newPatient.insuranceLastSheet"
                        >
                          <template #append>
                            <span id="insuranceExpirationDate">
                              <v-icon class="calender-icon"
                                >mdi-calendar-blank</v-icon
                              >
                            </span>
                          </template>
                        </v-text-field>

                        <date-picker
                          v-model="newPatient.insuranceExpirationDate"
                          element="insuranceExpirationDate"
                          color="#00a7b7"
                          :disabled="newPatient.insuranceLastSheet"
                      /></v-col>
                    </v-row>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="red-btn"
                    @click="
                      newPatientDialog = false;
                      newPatient = {};
                    "
                  >
                    لغو
                  </v-btn>
                  <span v-show="false">{{ reactivity }}</span>

                  <v-btn
                    class="primary-btn mx-1"
                    @click="submitNewPatient()"
                    :disabled="
                      newPatient.firstName == '' ||
                      newPatient.lastName == '' ||
                      newPatient.gender == '' ||
                      newPatient.mobile == '' ||
                      !checkNatCode(newPatient.natCode)
                    "
                  >
                    ثبت
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card class="cards pa-3">
        <v-card-text>
          <div>
            <v-row class="time-row">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                  label="*نام درخواست کننده"
                  v-model="applicator"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                  label="*نسبت با بیمار"
                  v-model="relation"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <h4 class="primary--text my-4 text-right">فیلتر بیماران</h4>
            <v-row class="time-row">
              <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                <v-text-field
                  label="نام"
                  v-model="filterName"
                  outlined
                  dense
                  @keyup.enter.native="
                    (filterName != '' ||
                      filterNatCode != '' ||
                      filterCode != '') &&
                    !Busy
                      ? filterPatients()
                      : ''
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                <v-text-field
                  type="number"
                  label="کدملی"
                  no-wheel
                  outlined
                  dense
                  v-model="filterNatCode"
                  @keyup.enter.native="
                    (filterName != '' ||
                      filterNatCode != '' ||
                      filterCode != '') &&
                    !Busy
                      ? filterPatients()
                      : ''
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                <v-text-field
                  type="number"
                  label="کد اشتراک"
                  no-wheel
                  outlined
                  dense
                  v-model="filterCode"
                  @keyup.enter.native="
                    (filterName != '' ||
                      filterNatCode != '' ||
                      filterCode != '') &&
                    !Busy
                      ? filterPatients()
                      : ''
                  "
                ></v-text-field>
              </v-col>
              <v-btn
                class="secondary-btn mt-4 mb-2 me-3 ms-3"
                style="float: left"
                @click="filterPatients()"
                :disabled="
                  (filterName == '' &&
                    filterNatCode == '' &&
                    filterCode == '') ||
                  Busy
                "
              >
                اعمال فیلتر
                <v-icon right>tune</v-icon>
              </v-btn>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="12">
                    <v-progress-circular
                      v-if="Busy"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                    <v-autocomplete
                      v-if="!Busy"
                      v-model="patientName"
                      :items="patientNames"
                      item-text="text"
                      item-value="id"
                      label="*نام بیمار"
                      class="pa-0 patientName"
                      outlined
                      dense
                      @change="
                        setDefault();
                        patientVisitsNumber();
                      "
                      @input="setDefault()"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="mt-2 ms-2 me-2"
                    v-if="
                      patientInfo.visitsNumbers >= 0 &&
                      patientName &&
                      !visitsBusy
                    "
                    cols="12"
                  >
                    <v-row class="pa-0">
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-center pa-0 mt-5"
                      >
                        <div>
                          <span
                            class="primary--text common-text font-weight-bold border py-1 px-5 rounded-lg"
                          >
                            تعداد مراجعه بیمار: {{ patientInfo.visitsNumbers }}
                          </span>
                        </div></v-col
                      >
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-center mt-5 pa-0 primary--text common-text align-items-center font-weight-bold"
                      >
                        <span
                          style="line-height: 20px"
                          class="py-1 px-5 rounded-lg"
                          :class="
                            patientInfo.mobileStatus ? 'correct' : 'incorrect'
                          "
                        >
                          شماره فعلی :
                          {{ patientInfo.mobile }}
                        </span>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-center pa-0 mt-3"
                      >
                        <v-btn
                          :class="
                            patientInfo.mobileStatus ? 'primary-btn' : 'red-btn'
                          "
                          class="mb-4"
                          @click="
                            changeMobileDialog = true;
                            newMobile = patientInfo.mobile;
                          "
                          ><span>ویرایش شماره بیمار</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-dialog v-model="changeMobileDialog" width="600">
                      <v-card class="pa-3">
                        <v-card-title class="mb-5"
                          >ویرایش تلفن بیمار</v-card-title
                        >
                        <v-card-text>
                          <v-text-field
                            v-model="newMobile"
                            outlined
                            dense
                            label="شماره موبایل جدید"
                            type="number"
                            :placeholder="patientInfo.mobile"
                          >
                          </v-text-field>
                          <v-btn
                            class="primary-btn submit-btn pa-2 me-2 ms-2"
                            @click="changeMobileNumber()"
                          >
                            ثبت
                          </v-btn>
                          <v-btn
                            class="red-btn pa-2"
                            @click="changeMobileDialog = false"
                          >
                            لغو
                          </v-btn>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <hr color="secondary" class="my-4" />
            <v-row>
              <v-col class="details-hidden">
                <v-select
                  label="*نوع خدمت"
                  v-model="requestType"
                  :items="requestTypes"
                  item-text="text"
                  item-value="value"
                  class="pa-0"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="details-hidden">
                <v-text-field
                  label="*آدرس محل سکونت"
                  v-model="address"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="time-row">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                  type="number"
                  label="*تلفن ثابت"
                  :no-wheel="true"
                  v-model="phoneNum"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                  type="number"
                  label="*شماره همراه"
                  :no-wheel="true"
                  v-model="patientInfo.mobile"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row class="mt-0 time-row">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="my-custom-input2">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateDate"
                    label=" تاریخ درخواستی "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>
                <date-picker
                  v-model="dateDate"
                  element="my-custom-input2"
                  color="#00a7b7"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateTime">
                  <v-text-field
                    outlined
                    dense
                    append-icon="schedule"
                    v-model="dateTime"
                    label=" ساعت درخواستی "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>
                <date-picker
                  v-model="dateTime"
                  element="dateTime"
                  color="#00a7b7"
                  type="time"
                />
              </v-col>
            </v-row>
            <v-card class="mt-3">
              <v-card-title class="ps-0 pe-0">
                <h3>جدول خدمات و افزودن خدمات مورد نیاز</h3>
              </v-card-title>
              <v-card-text class="ps-0 pe-0">
                <div>
                  <v-btn
                    @click="
                      newServiceDialog = true;
                      newService = {};
                    "
                    class="primary-btn my-5"
                    v-if="deviceType == 'mobile'"
                    >افزودن خدمت</v-btn
                  >
                  <b-table
                    v-else
                    responsive
                    show-empty
                    :items="freeServiceItems"
                    :fields="freeServiceFields"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.value }}
                      </div>
                    </template>
                    <template v-slot:cell(operation)="data">
                      <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                      <div style="text-align: center; vertical-align: middle">
                        <v-btn
                          class="ms-2 me-2 text-light primary-btn pa-3"
                          @click="addFreeService(data.item)"
                          :disabled="!data.item.name || !data.item.cost"
                          >افزودن</v-btn
                        >
                      </div>
                    </template>
                    <template v-slot:cell(cost)="data">
                      <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                      <div style="text-align: center; vertical-align: middle">
                        <vuetify-money
                          v-model="data.item.cost"
                          placeholder="قیمت"
                          :valueWhenIsEmpty="whenIsEmpty"
                          :options="vMoneyOptions"
                          type="number"
                          outlined
                          dense
                          style=""
                          class="mx-auto"
                        />
                      </div>
                    </template>

                    <template v-slot:cell(name)="data">
                      <div style="text-align: center; vertical-align: middle">
                        <v-text-field
                          v-model="data.item.name"
                          placeholder="نام خدمت"
                          class="pa-0"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </template>
                    <template v-slot:cell(patientCost)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.item.cost }}
                      </div>
                    </template>
                  </b-table>

                  <div>
                    <v-text-field
                      v-model="serviceFilter"
                      prepend-inner-icon="mdi-magnify"
                      label="جستجو"
                      single-line
                      hide-details
                      clearable
                      filled
                      rounded
                      class="text-right w-80 mb-2 search-input"
                    ></v-text-field>
                    <b-table
                      responsive
                      show-empty
                      empty-text="خدمتی برای نمایش وجود ندارد"
                      empty-filtered-text="خدمتی برای نمایش وجود ندارد"
                      :fields="
                        deviceType == 'mobile'
                          ? freeServiceFields
                          : serviceFields
                      "
                      :items="serviceItems"
                      :current-page="serviceCurrentPage"
                      :per-page="servicePerPage"
                      :busy="serviceBusy"
                      :filter="serviceFilter"
                      @filtered="serviceonFiltered"
                    >
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.value }}
                        </div>
                      </template>
                      <template v-slot:cell(index)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{
                            data.index +
                            servicePerPage * (serviceCurrentPage - 1) +
                            1
                          }}
                        </div>
                      </template>
                      <template v-slot:cell(patientCost)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ Number(data.value).toLocaleString() }}
                        </div>
                      </template>
                      <template v-slot:cell(clinicShare)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ Number(data.value).toLocaleString() }}
                        </div>
                      </template>
                      <template v-slot:cell(cost)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ Number(data.value).toLocaleString() }}
                        </div>
                      </template>
                      <template v-slot:cell(operation)="data">
                        <div style="text-align: center; vertical-align: middle">
                          <v-btn
                            class="text-light primary-btn pa-3"
                            @click="addtoNewService(data.item)"
                          >
                            افزودن
                          </v-btn>
                        </div>
                      </template>
                      <div
                        slot="table-busy"
                        class="text-center primary--text my-2"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          class="align-middle"
                        ></v-progress-circular>
                      </div>
                    </b-table>
                    <v-pagination
                      v-model="serviceCurrentPage"
                      :length="Math.ceil(serviceTotalRows / servicePerPage)"
                      :total-visible="5"
                      prev-icon="arrow_back"
                      next-icon="arrow_forward"
                      style="float: center"
                    ></v-pagination>
                  </div>

                  <hr />

                  <h5 class="primary--text mb-3">
                    <strong>خدمات درخواستی</strong>
                  </h5>

                  <b-table
                    :fields="
                      deviceType == 'mobile' ? freeServiceFields : serviceFields
                    "
                    :items="newItems"
                    :current-page="newCurrentPage"
                    :per-page="newPerPage"
                    :filter="newFilter"
                    responsive
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.value }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + newPerPage * (newCurrentPage - 1) + 1 }}
                      </div>
                    </template>
                    <template v-slot:cell(clinicShare)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ Number(data.value).toLocaleString() }}
                      </div>
                    </template>
                    <template v-slot:cell(description)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          data.item.description.substr(0, 100) +
                          (data.item.description.length > 100 ? "..." : "")
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(patientCost)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ Number(data.value).toLocaleString() }}
                      </div>
                    </template>
                    <template v-slot:cell(cost)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ Number(data.value).toLocaleString() }}
                      </div>
                    </template>
                    <template v-slot:cell(operation)="data">
                      <div style="text-align: center; vertical-align: middle">
                        <v-btn
                          class="red-btn pa-2"
                          outlined
                          @click="delService(data.index)"
                          ><v-icon>remove</v-icon></v-btn
                        >
                      </div>
                    </template>
                    <div
                      slot="table-busy"
                      class="text-center primary--text my-2"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="align-middle"
                      ></v-progress-circular>
                    </div>
                  </b-table>
                  <hr />
                  <h5 class="text-success mb-3 ms-3 me-3">
                    <strong
                      >قیمت کل:
                      {{
                        newItems
                          .map((x) => x.cost)
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                          .toLocaleString()
                      }} </strong
                    >ریال
                  </h5>
                  <hr />
                  <!-- <h6>
                    ثبت نحوه پرداخت و جزئیات پرداخت در این مرحله اجباری
                    <b>نمی‌باشد.</b>
                  </h6> -->
                  <v-row class="time-row mt-1">
                    <v-col
                      ><v-select
                        v-model="paymentStatus"
                        :items="paymentStatusOptions"
                        item-text="text"
                        item-value="value"
                        label="وضعیت پرداخت"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <div
                    v-if="paymentStatus == 'paid' || paymentStatus == 'partial'"
                  >
                    <v-row class="time-row mt-1">
                      <v-col
                        ><v-select
                          v-model="transferType"
                          :items="paymentTypes"
                          item-text="text"
                          item-value="value"
                          label="نحوه پرداخت*"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                      <v-col v-if="paymentStatus == 'partial'">
                        <vuetify-money
                          v-model="partialAmount"
                          label="*مبلغ پرداخت (ریال)"
                          :valueWhenIsEmpty="whenIsEmpty"
                          :options="vMoneyOptions"
                          type="number"
                          outlined
                          dense
                        />
                        <div
                          v-if="computedPartial"
                          class="red--text h4 mt-1 font-weight-bold"
                        >
                          *مبلغ وارد شده از مبلغ کل بیشتر میباشد.
                        </div>
                      </v-col>
                    </v-row>
                    <v-row
                      class="time-row"
                      v-if="
                        transferType == 'card' || transferType == 'cash-pos'
                      "
                    >
                      <v-col>
                        <div v-if="transferType == 'card'">
                          <div
                            style="border: 1px grey solid; border-radius: 8px"
                            class="px-3 py-2"
                          >
                            <h5>اطلاعات کارت‌ها</h5>
                            <v-row>
                              <v-col cols="12" sm="12" md="6" lg="6">
                                <v-text-field
                                  outlined
                                  dense
                                  type="number"
                                  class="mb-4"
                                  v-model="cardNumberSender"
                                  label="شماره کارت فرستنده*"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="6" lg="6">
                                <v-text-field
                                  outlined
                                  dense
                                  type="number"
                                  v-model="cardNumberReciever"
                                  label="شماره کارت گیرنده*"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                        <div v-if="transferType == 'cash-pos'">
                          <div
                            style="border: 1px grey solid; border-radius: 8px"
                            class="px-3 py-2"
                          >
                            <h6 class="mb-2 red--text" v-if="!cashPosValid()">
                              ! مجموع نقدی و کارتخوان با مبلغ نهایی برابر
                              نمی‌باشد.
                            </h6>
                            <vuetify-money
                              v-model="posAmount"
                              label="*مبلغ کارتخوان(ریال)"
                              :valueWhenIsEmpty="whenIsEmpty"
                              :options="vMoneyOptions"
                              type="number"
                              @input="onPosInput"
                              outlined
                              dense
                              class="mx-auto mb-2 details-hidden"
                            />
                            <vuetify-money
                              v-model="cashAmount"
                              label="*مبلغ نقدی(ریال)"
                              :valueWhenIsEmpty="whenIsEmpty"
                              :options="vMoneyOptions"
                              type="number"
                              outlined
                              dense
                              class="mx-auto details-hidden"
                            />
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <v-btn
              class="submit-btn primary-btn"
              :disabled="submitDisabled || !cashPosValid()"
              @click="submit()"
              >ثبت</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- receipt -->
    <v-card
      class="pa-2"
      v-show="false"
      style="float: left"
      id="Receipt"
      v-if="isMounted"
    >
      <div
        class="container mb-0"
        style="border: 2px solid black; padding: 5px 10px"
      >
        <div
          class="pa-1"
          style="
            text-align: center;
            margin: auto;
            font-size: 15px;
            border-bottom: 4px solid black;
          "
        >
          <img src="/receiptLogo.png" width="100" height="100" alt="" />
          <br />
          درمانگاه شبانه روزی پارسیان البرز
        </div>
        <p style="direction: rtl; font-weight: 900">
          <br />{{ " نام درخواست کننده: " + applicator }}
          <br />
          {{ "نام بیمار: " + userData.name }}
          <br />
          {{ "کد اشتراک بیمار: " + userData.code }}
          <br />
          {{ "نوع خدمت: " + requestType }}
          <br />
          {{ "تاریخ درخواستی: " + dateDate }}
          <br />
          {{ "ساعت درخواستی: " + dateTime }}
          <br />
          {{ "شماره همراه: " + printedMobile }}
          <br />
          {{ "شماره ثابت: " + phoneNum }}
          <br />
          {{ "آدرس: " + address }}
        </p>

        <b-table
          bordered
          :fields="Fields"
          :items="Items"
          small="small"
          responsive
        >
          <template v-slot:head()="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.label }}
            </div>
          </template>
          <template v-slot:cell(name)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(number)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(cost)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ Number(data.item.cost).toLocaleString() }}
            </div>
          </template>
        </b-table>
        <h4 style="text-align: right">
          {{
            "مبلغ کل:" + Number(userData.total.cost).toLocaleString() + "ریال"
          }}
        </h4>
        <br />
        <br />

        <div style="text-align: right; font-weight: 900">
          وضعیت پرداخت:

          {{
            paymentStatusOptions.filter((x) => x.value == "partial") &&
            Number(this.partialAmount) == Number(computedTotal)
              ? "پرداخت کامل"
              : paymentStatusOptions.filter((x) => x.value == paymentStatus)[0]
                  .text
          }}
        </div>

        <div
          style="text-align: right; font-weight: 900"
          v-if="paymentStatus == 'partial'"
        >
          {{
            "مبلغ پرداخت: " + Number(partialAmount).toLocaleString() + "ریال"
          }}
        </div>
        <div v-if="paymentStatus == 'partial' || paymentStatus == 'paid'">
          <div style="text-align: right; font-weight: 900">
            {{
              "نوع پرداخت: " +
              paymentTypes.filter((x) => x.value == transferType)[0].text
            }}
          </div>
          <div v-if="transferType == 'card' || transferType == 'cash-pos'">
            <div v-if="transferType == 'card'" style="font-weight: 900">
              شماره کارت فرستنده:
              {{ cardNumberSender ? cardNumberSender : "-" }}
              <br />
              شماره کارت فرستنده:
              {{ cardNumberReciever ? cardNumberReciever : "-" }}
            </div>
            <div v-if="transferType == 'cash-pos'" style="font-weight: 900">
              مبلغ کارتخوان:
              {{ posAmount ? Number(posAmount).toLocaleString() : "-" }} ریال
              <br />
              مبلغ نقدی:
              {{ cashAmount ? Number(cashAmount).toLocaleString() : "-" }} ریال
            </div>
          </div>
        </div>
        <hr />
        <br />
      </div>
      <br />
      <!-- <barcode value="http://my.parsianalborz.com" displayValue="false" width="2" height="60">
        </barcode>   -->
      <h4 style="text-align: center">http://my.parsianalborz.com</h4>
    </v-card>
    <!-- receipt -->
  </div>
</template>

<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { mapGetters } from "vuex";
import VueBarcode from "vue-barcode";
function checkCodeMeli(code) {
  //function of checking code melli
  var L = code.length;

  if (L < 8 || parseInt(code, 10) == 0) return false;
  code = ("0000" + code).substr(L + 4 - 10);
  if (parseInt(code.substr(3, 6), 10) == 0) return false;
  var c = parseInt(code.substr(9, 1), 10);
  var s = 0;
  for (var i = 0; i < 9; i++) s += parseInt(code.substr(i, 1), 10) * (10 - i);
  s = s % 11;
  return (s < 2 && c == s) || (s >= 2 && c == 11 - s);
}
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    barcode: VueBarcode,
  },
  data() {
    return {
      busySubmit: true,
      newPatient: {},
      freeNames: [],
      applicator: "",
      relation: "",
      patientName: "",
      patientVisitsTimes: "",
      visitsBusy: false,
      mobile: "",
      phoneNum: "",
      busy: true,
      price: "",
      date: moment(new Date()).format("jYYYY/jMM/jDD HH:MM"),
      dateDate: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTime: moment(new Date()).format("HH:mm"),
      states: [
        { value: "فعال", text: "فعال" },
        { value: "غیرفعال", text: "غیرفعال" },
      ],
      patientNames: [],
      drName: "",
      docotorsWithout: [],
      nurseName: "",
      outDoctor: "",
      isOut: false,
      insType: "",
      newCurrentPage: 1,
      newPerPage: 10,
      newTotalRows: "",
      newFilter: "",
      address: "",
      isBusy: false,
      requestId: "",
      freeServiceItems: [
        {
          name: "",
          cost: "",
        },
      ],
      freeOptions: [
        {
          value: "خدمات پرستاری",
          text: "خدمات پرستاری",
        },
        {
          value: "ویزیت",
          text: "ویزیت",
        },
        {
          value: "ویزیت تخصص",
          text: "ویزیت تخصص",
        },
      ],
      freeServiceFields: [
        { key: "name", label: "نام خدمت" },
        { key: "cost", label: "قیمت (ریال)" },
        { key: "operation", label: "عملیات" },
      ],
      serviceFields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام خدمت" },
        { key: "cost", label: "قیمت (ریال)" },
        { key: "operation", label: "عملیات" },
      ],
      serviceFilter: "",
      requestType: "",
      requestTypes: [
        { value: "ویزیت پزشک در منزل", text: "ویزیت پزشک در منزل" },
        { value: "ویزیت پرستار در منزل", text: "ویزیت پرستار در منزل" },
        { value: "فیزیوتراپی در منزل", text: "فیزیوتراپی در منزل" },
        { value: "تحویل دارو در منزل", text: "تحویل دارو در منزل" },
      ],
      serviceTotalRows: "",
      serviceCurrentPage: 1,
      servicePerPage: 10,
      serviceBusy: true,
      serviceItems: [],
      newItems: [],
      // reciept
      CurrentPage: 0,
      PerPage: 0,
      newPatientDialog: false,
      userService: "",
      reception: "",
      visitNo: "",
      userData: {},
      requestDate: "",
      userTotal: {},
      Fields: [
        { key: "name", label: "خدمت" },
        { key: "number", label: "تعداد" },
        { key: "cost", label: "قیمت" },
      ],
      Items: [],
      isMounted: false,
      // reciept
      genders: [
        { value: "زن", text: "زن" },
        { value: "مرد", text: "مرد" },
      ],
      drBusy: true,
      Busy: false,
      filterName: "",
      filterNatCode: "",
      filterCode: "",
      reactivity: false,
      patientInfo: [],
      changeMobileDialog: false,
      newMobile: "",
      changeBusy: false,
      transferType: "pos",
      cardNumberSender: "",
      cardNumberReciever: "",
      cashAmount: "",
      posAmount: "",
      paymentStatusOptions: [
        { text: "پرداخت کامل", value: "paid" },
        { text: "پرداخت جزئی", value: "partial" },
      ],
      paymentStatus: "paid",
      printedMobile: "",
      partialAmount: "",
      newService: {},
      newServiceDialog: false,
    };
  },
  computed: {
    ...mapGetters(["insurTypes", "shiftDetails", "userName", "paymentTypes"]),
    computedPartial() {
      if (
        this.partialAmount >
        this.newItems
          .map((x) => x.cost)
          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
      ) {
        return true;
      } else return false;
    },
    computedTotal() {
      if (this.newItems.length != 0) {
        return this.newItems
          .map((x) => x.cost)
          .reduce((a, b) => parseInt(a) + parseInt(b), 0);
      }
    },
    submitDisabled() {
      if (
        this.applicator &&
        this.relation &&
        this.patientName &&
        this.requestType &&
        this.address &&
        this.phoneNum &&
        this.mobile &&
        this.newItems.length &&
        this.paymentStatus
      ) {
        if (
          (this.paymentStatus == "partial" || this.paymentStatus == "paid") &&
          this.transferType
        ) {
          if (this.transferType == "card") {
            if (this.cardNumberSender && this.cardNumberReciever) {
              if (this.paymentStatus == "partial") {
                if (!this.computedPartial) {
                  return false;
                } else return true;
              }
              return false;
            } else return true;
          }
          if (this.transferType == "cash-pos") {
            if (this.cashAmount && this.posAmount) {
              if (this.paymentStatus == "partial") {
                if (!this.computedPartial) {
                  return false;
                } else return true;
              }
              return false;
            } else return true;
          }
          if (this.transferType == "pos" || this.transferType == "cash") {
            if (this.paymentStatus == "partial") {
              if (!this.computedPartial) {
                return false;
              } else return true;
            }
          }
        } else if (
          (this.paymentStatus == "partial" || this.paymentStatus == "paid") &&
          !this.transferType
        ) {
          return true;
        }
      } else return true;
    },
  },
  methods: {
    isID(id) {
      //cheking code melli
      // NOTE (m-isID) to check the validity of the national code
      return id
        ? checkCodeMeli(id)
          ? true
          : "کدملی وارد شده صحیح نمی‌باشد"
        : true;
    },
    onPosInput() {
      let total;
      if (this.paymentStatus == "partial") {
        total = this.partialAmount;
      } else if (this.paymentStatus == "paid") {
        total = this.newItems
          .map((x) => x.cost)
          .reduce((a, b) => parseInt(a) + parseInt(b), 0);
      }
      if (total) {
        if (parseInt(this.posAmount) < parseInt(total)) {
          this.cashAmount = parseInt(total) - parseInt(this.posAmount);
        } else {
          this.cashAmount = 0;
        }
      }
    },
    cashPosValid() {
      let total;
      if (this.paymentStatus == "partial") {
        total = this.partialAmount;
      } else if (this.paymentStatus == "paid") {
        total = this.newItems
          .map((x) => x.cost)
          .reduce((a, b) => parseInt(a) + parseInt(b), 0);
      }
      if (this.transferType == "cash-pos") {
        if (this.cashAmount && this.posAmount) {
          if (
            parseInt(total) ==
            parseInt(this.cashAmount) + parseInt(this.posAmount)
          ) {
            return true;
          } else return false;
        } else return false;
      } else {
        return true;
      }
    },
    checkNatCode(id) {
      return id ? (checkCodeMeli(id) ? true : false) : true;
    },
    openNewPatient() {
      (this.newPatient = {
        firstName: "",
        lastName: "",
        gender: "",
        mobile: "",
        insurType: "",
        insurNo: "",
        natCode: "",
        insuranceExpirationDate: "",
        insuranceLastSheet: false,
      }),
        (this.newPatientDialog = true);
    },
    submitNewPatient() {
      // we send the changes to the base and then receive infos and then we set our data to
      this.isBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/new",
          {
            gender: this.newPatient.gender,
            firstName: this.newPatient.firstName,
            lastName: this.newPatient.lastName,
            mobile: this.newPatient.mobile,
            tel: this.newPatient.tel,
            address: this.newPatient.address,
            insuranceId: this.newPatient.insurType,
            insurNo: this.newPatient.insurNo,
            natCode: this.newPatient.natCode,
            insuranceExpirationDate: this.newPatient.insuranceLastSheet
              ? ""
              : this.newPatient.insuranceExpirationDate,
            insuranceLastSheet: this.newPatient.insuranceLastSheet,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 201) {
            // we change our data to infos that we get
            this.filterName =
              this.newPatient.firstName + " " + this.newPatient.lastName;
            this.filterNatCode = this.newPatient.natCode;
            this.filterPatients();
            this.mobile = this.newPatient.mobile;
            this.address = this.newPatient.address;
            this.phoneNum = this.newPatient.tel;
            this.toast("بیمار با موفقیت ثبت شد.", "success");
            this.newPatient = {};
            this.newPatientDialog = false;
            this.patientName = res.data;
            this.setDefault();
            this.isBusy = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.isBusy = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.isBusy = false;
        });
    },
    filterPatients() {
      // setting our pationt data that we need with mapping through infos that server give us
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/filter",

          {
            name: this.filterName,
            natCode: this.filterNatCode || "",
            code: this.filterCode || "",
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.patientName = "";
            this.serviceCurrentPage = 1;
            this.patientNames = res.data.map((x) => {
              return {
                id: x.id,
                text: x.name,
                mobile: x.mobile,
                address: x.address,
                tel: x.tel,
                insurance: x.insuranceId,
                nameW: x.nameW,
                code: x.code,
              };
            });
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    addtoNewService(item) {
      //pushing our new items
      this.serviceTotalRows = this.serviceItems.length;
      this.newItems.push(item);
      this.newTotalRows = this.newItems.length;
    },
    addFreeService(item) {
      this.serviceTotalRows = this.serviceItems.length;
      this.newItems.push(item);
      this.newTotalRows = this.newItems.length;
      this.freeServiceItems = [
        {
          name: "",
          cost: "",
        },
      ];
      this.newServiceDialog = false;
      this.newService = {};
    },
    delService(order) {
      this.newItems.splice(order, 1);
    },
    patientVisitsNumber() {
      this.visitsBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/visitsNumber",

          {
            patientId: this.patientName,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.visitsBusy = false;
          if (res.status == 200) {
            this.patientInfo = res.data;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.visitsBusy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    changeMobileNumber() {
      this.changeBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/editMobile",

          {
            patientId: this.patientName,
            mobile: this.newMobile,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.changeBusy = false;
          if (res.status == 200) {
            this.changeMobileDialog = false;
            this.newMobile = "";
            this.patientVisitsNumber();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.changeBusy = false;
          this.changeMobileDialog = false;
          this.newMobile = "";
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    setDefault() {
      //setting deafault patien mobile address phone num
      try {
        this.mobile = this.patientNames.filter(
          (x) => x.id == this.patientName
        )[0].mobile;
      } catch (error) {
        this.mobile = "";
      }
      try {
        this.address = this.patientNames.filter(
          (x) => x.id == this.patientName
        )[0].address;
      } catch (error) {
        this.address = "";
      }
      try {
        this.phoneNum = this.patientNames.filter(
          (x) => x.id == this.patientName
        )[0].tel;
      } catch (error) {
        this.phoneNum = "";
      }
    },
    eraseNurse() {
      this.nurseName = "";
    },
    eraseDoc() {
      //getting info of service items from server then set them as free names by mapping though them
      this.drName = "";
    },
    allServices() {
      this.$http
        .post(
          this.baseUrl + "/clinic/services/outServices",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.serviceItems = res.data;
            this.freeNames = this.serviceItems.map((x) => {
              return {
                id: x.id,
                text: x.name,
              };
            });
            this.serviceBusy = false;
            this.serviceTotalRows = this.serviceItems.length;
          } else {
            this.toast("خطا ", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    submit() {
      //submitting the information of pationt that we set and change the data to empty again
      this.vLoading = true;
      this.busySubmit = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/homeCare/visit/new",

          {
            patientId: this.patientName,
            date: this.dateDate + this.dateTime,
            services: JSON.stringify(this.newItems),
            demander: this.applicator,
            totalCost:
              this.newItems.length != 0
                ? String(
                    this.newItems
                      .map((x) => x.cost)
                      .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                  )
                : "0",
            paymentStatus:
              this.paymentStatus == "partial" && !this.partialAmount
                ? "unpaid"
                : this.paymentStatus == "partial" &&
                  Number(this.partialAmount) == Number(this.computedTotal)
                ? "paid"
                : this.paymentStatus,
            paidCost:
              this.paymentStatus == "partial"
                ? this.partialAmount
                : this.paymentStatus == "paid"
                ? this.newItems
                    .map((x) => x.cost)
                    .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                : "0",
            address: this.address,
            mobile: this.patientInfo.mobile,
            clock: this.dateTime,
            demandType: this.requestType,
            relation: this.relation,
            shiftId: this.shiftDetails.id,
            tel: this.phoneNum,
            transferType: this.transferType,
            cardNumberSender:
              this.transferType == "card" ? this.cardNumberSender : "",
            cardNumberReciever:
              this.transferType == "card" ? this.cardNumberReciever : "",
            cash: this.transferType == "cash-pos" ? this.cashAmount : "",
            pos: this.transferType == "cash-pos" ? this.posAmount : "",
            //partialPayment: this.partialPayment,
            //partialPaymentAmount: this.partialPayment
            //  ? this.partialPaymentAmount
            //  : "",
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 201) {
            this.userData = new Object({
              name: this.patientNames.filter((x) => x.id == this.patientName)[0]
                .nameW,
              code: this.patientNames.filter((x) => x.id == this.patientName)[0]
                .code,
              total: res.data,
            });
            this.Items = res.data.services;
            this.reception = this.userName;
            this.toast("درخواست با موفقیت ثبت گردید", "success");
            this.newPatient = "";
            this.filterName = "";
            this.filterNatCode = "";
            this.filterCode = "";
            this.printedMobile = this.patientInfo.mobile;
            this.requestId = res.data.id;
            this.isMounted = true;
            setTimeout(() => {
              this.patientInfo = {};
            }, 200);

            this.vLoading = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
            this.busySubmit = false;
          }
        })
        .then(() => {
          this.isMounted = false;
          this.popup();
        })
        .then(() => {
          this.applicator = "";
          this.relation = "";
          this.patientName = "";
          this.mobile = "";
          this.address = "";
          this.phoneNum = "";
          this.requestType = "";
          this.newItems = [];
          this.busy = true;
          this.date = moment(new Date()).format("jYYYY/jMM/jDD HH:MM");
          this.dateDate = moment(new Date()).format("jYYYY/jMM/jDD");
          this.dateTime = moment(new Date()).format("HH:mm");
          //transferType details set to empty for next request
          this.transferType = "pos";
          this.cardNumberSender = "";
          this.cardNumberReciever = "";
          this.cashAmount = "";
          this.posAmount = "";
          // reciept
          this.CurrentPage = 0;
          this.PerPage = 0;
          this.userService = "";
          this.reception = "";
          this.visitNo = "";
          this.userData = {};
          this.requestDate = "";
          this.userTotal = {};
          this.paymentStatus = "";
          this.partialAmount = "";
          this.Items = [];
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
          this.busySubmit = false;
        });
    },
    popup() {
      //NOTE popup new window with information of receipt for user
      var myWindow = window.open("#", "Receipt", "height=auto,width=800");
      //making a new html for popup
      myWindow.document.write(
        //head and styling of receipt
        "<html><head><link rel='stylesheet' href='/receipt.css'><title>Receipt</title>"
      );
      myWindow.document.write("</head><body>"); //closing head and opening body
      myWindow.document.write(document.getElementById("Receipt").innerHTML); //importing html of #receipt div to body of receipt popup
      myWindow.document.write("</body></html>");
      myWindow.document.close();

      myWindow.onload = function () {
        //this function calls when the popup mounted
        myWindow.focus();
        setTimeout(() => {
          myWindow.print();
          myWindow.close();
        }, 500);
      };
    },
    serviceonFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.serviceTotalRows = filteredItems.length;
      this.serviceCurrentPage = 1;
    },
  },
  mounted() {
    this.allServices();
  },
};
</script>

<style lang="scss">
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 85%;
}
</style>
